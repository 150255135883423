
.logo {
  height: 10vmin;
  pointer-events: none;
}

.header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.tabsWrapper {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.tableWrapper {
  padding: 20px;
}

